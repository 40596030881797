import React from "react";
import "../SCSS/HeadertTitle.scss";

function HeaderTitle() {
	return (
		<div className="header__title">
			<h1 className="header__title__first-line">Damn</h1>
			<h2 className="header__title__second-line">Good</h2>
			<h2 className="header__title__third-line">Code</h2>
		</div>
	);
}

export default HeaderTitle;
